.container {
  display: flex;
  align-items: center;
}

.element {
  display: flex;
  align-items: center;
  margin-right: 8px;
  flex-shrink: 0;
  width: 28px;
  justify-content: space-between;
}