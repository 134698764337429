@import "src/styles/mixins.scss";

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin fadeAnimation {
  animation: fadeAnimation 250ms;
  animation-fill-mode: forwards;
}

@mixin fadeAnimationLonger {
  animation: fadeAnimation 500ms;
  animation-fill-mode: forwards;
}

