.carouselContainer {
  z-index: 1;
}

.leftArrow {
  @media screen and (min-width: 1024px) {
    position: absolute;
    left: 0px;
    z-index: 1;
    display: inline-block;
    opacity: 0.8;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    visibility: hidden;
  }
}

.rightArrow {
  @media screen and (min-width: 1024px) {
    position: absolute;
    right: 0px;
    z-index: 1;
    display: inline-block;
    opacity: 0.8;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    visibility: hidden;
  }
}

.buttonGroup {
  position: absolute;
  top: 0;
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  visibility: hidden;
}
