@import 'src/styles/mixins.scss';
@import 'src/styles/fonts-mixins.scss';

.homeContainer {
  display: flex;
}

.listContainer {
  align-items: center;
}

.listItems {
  display: flex;
  padding-bottom: 10px;
}

.listText {
  align-items: center;
  display: flex;
  padding-left: 15px;
}

.listTextNewContent {
  display: flex;
  padding-left: 15px;
}

.newListItem {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.listBullet {
  padding-right: 10px;
}
.listIcons {
  align-self: center;
  @include themify {
    color: themed('mission_icon_color');
  }
}

.listTextContent {
  display: flow;
}

.customContent {
  max-width: 40rem;
}

