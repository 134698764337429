@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';

.validationContainer {
  position: absolute;
  margin-left: 56%;
  margin-top: -3px;
}

.editContainer {
  position: absolute;
  margin-left: -56%;
  margin-top: -3px;
}

.checkIconButton {
  padding: 0;
  margin-left: 5px;
}

.validationTableCell {
  padding: 0;
  text-align: center;
  width: 50%;
  height: 60px;
  @include themify {
    border: 2px themed('cell-border') solid;
  }
}

.bold {
  @include font-text-bold;
}
