@import 'src/styles/fonts-mixins';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  width: 600px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.messagesContainer {
  max-height: 400px;
  overflow-y: auto;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 500px;
  margin-bottom: 7px;
  margin-top: 7px;
}

.infosContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
}

.nameContainer {
  @include font-text;
  margin-right: 6px;
}

.dateContainer {
  @include font-select;
  @include themify {
    color: themed('text-grey');
  }
}

.addContainer {
  width: 100%;
  margin-top: 8px;
}

.addInput {
  width: 100%;
}

.messageIconDisabled {
  @include themify {
    color: themed('icon-color');
  }
}

.cancellationCommentary{
  @include themify {
    color: themed('status-billToCancel');
  }
}

.noComment {
  margin-bottom: 15px;
}
