@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';

$monthCellWidth: 10%;
$poNameCellWidth: 20%;

.nameCellWidth {
  width: $poNameCellWidth;
}

.noUnderline {
  border-bottom: 0 solid;
  padding: 0;
  th {
    height: max-content;
  }
}

.yearCell {
  text-align: center;
}

.monthCell {
  text-transform: capitalize;
  width: $monthCellWidth;
}

.changeMonthButton {
  @include themify {
    background-color: themed('text-grey');
    color: white;
  }
  border-radius: 0;
}

.fullUnderline {
  th {
    height: min-content;
  }
}
