@import 'src/styles/mixins.scss';
@import 'src/styles/fonts-mixins.scss';

.asterisk {
  color: red;
  margin-left: 3px;
}

.disabled {
  @include themify {
    background-color: themed('disabled-background');
  }
}

.onFocus {
  :focus {
    @include themify {
    background-color: themed('color-background');
  }
  }
}