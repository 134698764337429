@import 'src/styles/mixins';

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  @include themify {
    background-color: themed('table-background');
    box-shadow: 0px 7px 5px -2px rgba(0, 0, 0, 0.05);
  }
}

.stickyFooter {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  bottom: 0;
  @include themify {
    background-color: themed('table-background');
    box-shadow: 0px -7px 5px -2px rgba(0, 0, 0, 0.05);
  }
}
