@import 'src/styles/mixins';
@import 'src/styles/animation';

.margin_top_title {
  margin-top: 35px;
}

.margin_bot_title {
  margin-bottom: 20px;
}

.bill_title {
  margin-top: 40px;
}

.subTitle {
  margin-top: 10px;
  margin-bottom: 20px;
}

.subTitleControl {
  margin-top: 10px;
}

.myMission {
  margin-top: 40px;
  margin-bottom: 20px;
}

.missionList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.missionName {
  margin: 5px;
}

.mainTitle {
  margin-top: 20px;
  margin-bottom: 50px;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
}

.filters {
  display: flex;
}

.statusChip {
  margin: 2px;
}

.loader {
  text-align: center;
  padding: 40px;
  @include fadeAnimationLonger;
}

.billTable {
  @include fadeAnimationLonger;
}
