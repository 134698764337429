@import 'src/styles/mixins.scss';
@import 'src/styles/fonts-mixins';
@import 'src/styles/variables';

.emptyMissionCell {
  width: 55%;
  border: 0;
  padding: 0;
}

.smallDateCell {
  width: 8.4%;
}

.largeDateCell {
  width: 10.5%;
}

.emptyEndCell {
  width: 3%;
  border: 0;
  padding: 0;
}

.monthSpan {
  @include font-month-name;
  align-items: center;
  text-align: center;
  padding: 0;
}
.arrowRightCell {
  border: none;
  align-items: end;
  text-align: end;
  padding: 0 18px 0 0;
}

.arrowLeftCell {
  border: none;
  align-items: start;
  text-align: start;
  padding: 0 0 0 18px;
}

.selectMonthCell {
  border: none;
  padding: 0;
}
