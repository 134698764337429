.root {
  margin: 20px 0;
}

.footer_root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px !important;
}
