.root {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

.text {
  padding-right: 10px;
  font-weight: bold;
  white-space: nowrap;
  width: 180px;
}
