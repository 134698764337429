@import 'styles/mixins.scss';

.root {
  display: flex;
  align-items: center;
}

.icon {
  @include themify {
    background-color: themed('approved-input');
    border-radius: 50%;
  }
  color: white;
  height: 15px !important;
  margin-right: 5px;
  width: 15px !important;
}

.text {
  font-size: 11px;
}
