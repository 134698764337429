@import 'styles/mixins.scss';

.root {
  display: flex;
  align-items: center;
}

.icon {
  border-radius: 50%;
  height: 15px !important;
  margin-right: 5px;
  width: 15px !important;

  @include themify {
    border: 1px solid
  }
}

.text {
  font-size: 11px;
}
