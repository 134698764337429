@import 'styles/mixins.scss';
@import "src/styles/fonts-mixins.scss";

.title {
  @include font-input-title;
  margin-bottom: 14px;
}


.asterisk {
  color: red;
}
