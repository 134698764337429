@import 'src/styles/mixins';

$maxCellWidth: 185px;
$valueCellWidth: 11.4%;

@mixin collapseCellStyle {
  border-top: 0;
  border-bottom: 0;
}

.collapseTable {
  padding: 0;
  margin: 0;
}

.collapseCell {
  text-align: center;
  max-width: $maxCellWidth;
  padding: 0px;
  white-space: nowrap;
  @include themify {
    border-left: 1px themed('cell-border') solid;
    border-right: 1px themed('cell-border') solid;
  }
  input {
    font-size: 14px;
  }
  @include collapseCellStyle;
}

.collapseContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}

.nameCell {
  @include themify {
    border-left: 1px themed('cell-border') solid;
    border-right: 1px themed('cell-border') solid;
    color: themed('mission_icon_color');
  }
  text-align: justify;
  @include collapseCellStyle;
}

.nameCellContainer {
  max-width: 85%;
  width: 85%;
}


.totalCell {
  text-align: center;
  @include themify {
    border-left: 1px themed('cell-border') solid;
    border-right: 2px themed('cell-border') solid;
    color: themed('mission_icon_color');
  }
  @include collapseCellStyle;
}
