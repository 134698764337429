@import 'src/styles/mixins.scss';

.actionContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  margin-top: -40px;
  transition: all 250ms;
  @include themify {
    background: linear-gradient(to bottom, transparent, themed('color-surface'));
  }
  z-index: 1;
}

.actionContainerOpen {
  @include themify {
    background: transparent;
  }
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
  transition: all 250ms
}

.expand {
  margin-bottom: -10px;
  transform: rotate(0deg);
  transition: all 250ms;
}

.expandOpen {
  transform: rotate(180deg);
}
