@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.icon {
  @include themify {
    color: themed('text-grey');
  }
}

.logo {
  max-height: 25px;
  @include themify {
    filter: themed('logo-filter');
  }
}

.logoContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.divider {
  @include themify {
    background-color: themed('text-surface');
  }
  opacity: 0.2;
}

.drawer {
  padding-top: 20px !important;
  min-width: $drawerWidth !important;
  @include themify {
    background-color: themed('color-surface');
  }
}

