@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';
@import 'src/styles/animation';

.alert {
  margin-top: 20px;
}

.cardLoading {
  width: 100%;
  margin: 15px;
  height: 85px;
  @include fadeAnimation;
}

.loadingBilling {
  position: relative;
  top: 20%;
}