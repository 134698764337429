.root {
  display: flex;
  align-items: center;
}

.numberDay {
  font-weight: bold;
  white-space: nowrap;
  width: 190px;
}

.halfDay {
  font-weight: bold;
  white-space: nowrap;
  width: 140px;
  padding-left: 30px;
}

.formControl {
  margin: 8px;
  min-width: 120px;
}
