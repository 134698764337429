@import 'src/styles/fonts-mixins';
@import 'src/styles/animation';

.mainTitle {
  margin-top: 20px;
  margin-bottom: 50px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.statusChip {
  margin: 2px;
}

.loader {
  text-align: center;
  padding: 40px;
  @include fadeAnimationLonger;
}

.billTable {
  @include fadeAnimationLonger;
}

.paiementTable {
  margin-left: -20px;
}

.paiementTableSelectable {
  margin-left: -35px;
}

.controlBillTableSelectOn {
  margin-left: -28px;
}

.controlBillTable {
  margin-left: -10px;
}

.managerTableSelectOn {
  margin-left: -28px;
}

.managerBillTable {
  margin-left: -10px;
}

.missionCell {
  display: flex;
}

.missionName {
  @include ellipsis-text;
}

.missionIcon {
  margin-bottom: 2px;
  margin-right: 5px;
  font-size: 20px;
  @include themify {
    fill: themed('mission_icon_color');
  }
}

.pagination {
  float: right;
  border: none;
}

.input {
  margin-right: 15px;
}