@import 'src/styles/mixins.scss';

.billChip {
  height: 40px;
  border-radius: 20px;
}

.selected {
  @include themify {
    color: white;
  }
}

.billTitle {
  margin-left: 5px;
}

.toBillChecked {
  @include themify {
    background-color : themed('status-toBill');
  }
}

.toBillNewChecked {
  @include themify {
    background-color : themed('status-toBillNew');
  }
}

.billedChecked {
  @include themify {
    background-color : themed('status-billed');
  }
}

.sentChecked {
  @include themify {
    background-color : themed('status-sent');
  }
}

.toCancelChecked {
  @include themify {
    background-color : themed('status-billToCancel');
  }
}

.canceledChecked {
  @include themify {
    background-color : themed('status-billCanceled');
  }
}

.draftChecked {
  @include themify {
    background-color : themed('status-billDraft');
  }
}

.pendingChecked {
  @include themify {
    background-color: themed('status-billPaymentPending');
  }
}

.paidChecked {
  @include themify{
    background-color: themed('status-billPaymentPaid');
  }
}

.recoveringChecked {
  @include themify {
    background-color: themed('status-billPaymentRecovering');
  }
}

.litigationChecked {
  @include themify {
    background-color: themed('status-billLitigation');
  }
}