@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';

.text {
  margin-top: 30px;
  display: flex;
}

.infosContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.deletionComment {
  margin-bottom: 20px;
}

.bold{
  @include font-text-bold;
}

.statusIcon {
  display: flex;
  position: relative;
  transform: scale(0.9);
  bottom: 2px;
}

.pickers {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.datePicker {
  margin-right: 30px;
}

.alert {
  margin-top: 20px;
}

.cloneIcon {
  @include themify {
    color: themed('mission_icon_color');
  }
}

.underMainText {
  margin-top: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.statusContainer {
  margin-left: 5px;
}