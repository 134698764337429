.root {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin-bottom: 10px;
  padding-left: 24px;
}

.filesContainer {
  max-height: 500px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.textContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
