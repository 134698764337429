@import 'styles/mixins.scss';

.title {
  font-size: 28px;

  margin: 30px 0;
}
.summaryContainer {
  margin-top: 20px;
}
.summaryContent{
  height: 70px;
  display: flex; /* contexte sur le parent */
  vertical-align: center;
  align-items: center;
  &_icon {
    height: 70px;
    font-size: 35px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  &_divider {
    max-height: 50px;
    width: 3px !important;
    margin-right: 20px !important;
  }
  &_green {
    background: rgba(222, 239, 222, 0.81) !important;
    border: 1px solid rgba(161, 43, 37, 0.01);
    color: rgba(52, 123, 50, 1) !important;
  }
  &_red {
    background: rgba(220, 71, 71, 0.11) !important;
    border: 1px solid rgba(161, 43, 37, 0.01);
    color: rgba(161, 43, 37, 1) !important;
  }
  &_grey {
    background: rgba(147, 147, 147, 0.28)!important;
    border: 1px solid rgba(161, 43, 37, 0.01);
    color: rgba(51, 51, 51, 1) !important;
  }
}

.statusContainer {
  width: 120px;
  display: inline-block;
  vertical-align: middle;
}

.daysRemainingContainer {
  width: 200px;
  display: inline-block;
  vertical-align: middle;
}

.subTitleContainer {
  display: flex;
}

.subTitleTooltip {
  transform: scale(1.3);
}

.subTitle {
  font-size: 22px;
  font-weight: 700;
}

.mainComponent {
  padding: 15px 35px;
  margin-top: 20px;
  font-family: 'Outfit', 'Open Sans', sans-serif;
}

.disabledInputContainer {
  @include themify {
    background-color: themed('input-background-disabled');
    border: 1px solid themed('input-border');
  }
  border-radius: 5px;
}

.buttonsContainer {
  display: flex;

  margin-top: 20px;
}

.spanningButton {
  flex: 1 1 0;
  margin: 0 15px !important;
}
