.root {
  display: flex;
  align-items: center;
}

.icon {
  background-color: rgba(#d61d1d, 0.81);
  border-radius: 50%;
  color: white;
  height: 15px;
  margin-right: 5px;
  width: 15px;
}

.text {
  font-size: 11px;
}
