@import 'styles/mixins.scss';

.triangle {
  @include themify {
    background-color: themed('color-surface');
    box-shadow: 0 0 4px themed('notification-box-color');
  }
}

.rectangle {
  @include themify {
    background: themed('color-surface');
    box-shadow: 0 4px 4px themed('notification-box-color');
  }

  width: max-content;
  border-radius: 3px;
}

.rectangleRight {
  transform-origin: top left;
  transform: scale(-1, 1);
}

.childrenRight {
  transform: scale(-1, 1);
}

.smallTriangle {
  width: 15px;
  height: 15px;

  transform: rotate(-45deg);
}

.smallRectangle {
  position: absolute;

  top: 8px;
  left: -15px;

  padding: 10px 20px;
}

.smallRectangleRight {
  left: 35px;
}

.mediumTriangle {
  position: relative;
  top: 12px;

  width: 20px;
  height: 20px;

  transform: rotate(-45deg);
}

.mediumRectangle {
  position: absolute;

  top: 16px;
  left: -25px;

  padding: 10px 20px;
}

.mediumRectangleRight {
  left: 45px;
}

.bigTriangle {
  position: relative;
  top: 12px;

  width: 20px;
  height: 20px;

  transform: rotate(-45deg);
}

.bigRectangle {
  position: absolute;

  top: 21px;
  left: -60px;

  padding: 10px 20px;
}

.bigRectangleRight {
  left: 95px;
}
