@import 'styles/mixins.scss';

@import 'styles/mixins.scss';

.root {
  width: 100%;
  @include themify {
    background-color: themed('color-surface') !important;
  }
}

.containerPaper {
  padding: 30px;
  background-color: red !important;
}