@import 'styles/mixins.scss';
@import "styles/fonts-mixins.scss";


.tooltip {
  @include tooltip;
  &_orange {
    background-color: rgba(250, 185, 125) !important;
  }
  &_red {
    background-color: rgba(161, 43, 37, 1) !important;
  }
}

.arrow {
  &_orange {
    color: rgba(250, 185, 125) !important;
  }
  &_red {
    color: rgba(161, 43, 37, 1)!important;;
  }

}