@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';

$monthCellWidth: 10%;

.noUnderline {
  border-bottom: 0 solid;
  padding: 0;
  th {
    height: max-content;
  }
}

.yearCell {
  text-align: center;
}

.tableCell {
  @include themify {
    border: 1px themed('cell-border') solid;
  }
}

.monthCell {
  text-transform: capitalize;
  width: $monthCellWidth;
}

.estimatedMonthHead {
  @include themify {
    background-color: rgba(themed('color-red'), 0.15);
    border: 1px themed('cell-border') solid;
  }
}

.estimatedMonth {
  padding: 0;
  white-space: nowrap;
  @include themify {
    background-color: rgba(themed('color-red'), 0.10);
  }
}

.changeMonthButton {
  @include themify {
    background-color: themed('text-grey');
    color: white;
  }
  border-radius: 0;
}

.monthContainer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
}

.fullUnderline {
  th {
    height: min-content;
  }
}
