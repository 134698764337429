@use "sass:math";
@import 'styles/mixins.scss';

.root {
  max-width: 800px;
  margin: 30px auto;
}

.title {
  font-size: 24px !important;
  margin-bottom: 20px !important;
  font-weight: bold;

  &_error {
    color: #dc4747 !important;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  @include themify {
    color: themed('text-surface');
  }
}

.error_icon {
  fill: #dc4747 !important;
  background-color: #ffe7e7;
  border-color: #ffe7e7;
  border-radius: 50%;

  @include themify {
    border-style: solid;
    border-width: math.div(themed('title-size'), 8) !important;
    width: math.div(themed('title-size'), 1.5) !important;
    height: math.div(themed('title-size'), 1.5) !important;
  }

  margin-right: 2%;
  margin-top: 2px;
}

.contact_button_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_button {
  width: 326px;
  height: 40px;
}

.body {
  font-size: 14px !important;
  margin-bottom: 50px !important;
  @include themify {
    color: themed('text-surface');
  }
}

.password_entry {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  @include themify {
    color: themed('text-surface');
  }
}

.btnMargin > button {
  margin-left: 20px;
}