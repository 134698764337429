@import 'src/styles/mixins.scss';
@import 'src/styles/fonts-mixins';

.extraRightUnderline {
  @include themify {
    -webkit-box-shadow: inset 0px -1px themed('cell-border');
    -moz-box-shadow: inset 0px -1px themed('cell-border');
    box-shadow: inset 0px -1px themed('cell-border');
  }
  width: 20px;
  display: table-cell;
}

.firstCellBorder {
  @include themify {
    border-bottom: 1px solid themed('cell-border');
  }
  width: 92%;
  float: right;
}

.underlinedCell {
  @include themify {
    -webkit-box-shadow: inset 0px -1px themed('cell-border');
    -moz-box-shadow: inset 0px -1px themed('cell-border');
    box-shadow: inset 0px -1px themed('cell-border');
  }
}

.isInvalid {
  @include themify {
    box-shadow: inset 2px 0 themed('bad-input'),
      inset -2px 0 themed('bad-input');
  }
}

.lastInvalidCell {
  @include themify {
    background: themed('invalid-cell-background');
  }
}

.firstInvalidCell {
  @include themify {
    background: themed('invalid-cell-background');
  }
}

.firstLastInvalidCell {
  @include themify {
    background: themed('invalid-cell-background');
  }
}

.warningCell {
  @include themify {
    background: themed('warning-cell-background');
  }
}

$mission-row-padding: 7px 5px 7px 5px;

.nameCell {
  @include themify {
    border: 1px themed('cell-border') solid;
    border-bottom: 0;
    border-top: 0;
  }
  width: 20%;
  padding: $mission-row-padding;
  text-align: center;
}

.nameHeadCell {
  @include themify {
    border: 0 themed('cell-border') solid;
    border-left-width: 1px;
  }
  text-align: center;
  width: 20%;
}

.missionCell {
  text-align: left;
  @include themify {
    border: 1px themed('cell-border') solid;
    border-bottom: 0;
    border-top: 0;
  }
  width: 30%;
  padding: $mission-row-padding;
  background-color: #f8f8f8;
}

.missionHeadCell {
  text-align: center;
  width: 30%;
}

.commonCell {
  @include themify {
    border: 1px themed('cell-border') solid;
    border-bottom: 0;
    border-top: 0;
  }
  display: 'flex';
  background-color: #f8f8f8;
}

.hatchedCell {
  @include themify {
    border: 1px themed('cell-border') solid;
    border-bottom: 0;
    border-top: 0;
  }
  color: #8d8d8d;
  background-color: #f8f8f8;
  text-align: center;
  height: 40px;
  padding: 0;
  @include themify {
    background: repeating-linear-gradient(
      135deg,
      themed('input-background-disabled'),
      themed('input-background-disabled') 14px,
      themed('input-border') 14px,
      themed('input-border') 16px
    );
  }
}

.emptyContent {
  height: 100%;
  width: 100%;
}

.smallCell {
  width: 9%;
}

.normalCell {
  width: 11.25%;
}

.totalCell {
  text-align: center;
  @include themify {
    border: 1px themed('cell-border') solid;
    border-bottom: 0;
    border-top: 0;
  }
  width: 5%;
  padding: $mission-row-padding;
}

.totalHeadCell {
  @include themify {
    border: 0 themed('cell-border') solid;
    border-right-width: 1px;
  }
  text-align: center;
  width: 5%;
  padding: $mission-row-padding;
}

.totalCommunCell {
  @include themify {
    border: 1px themed('cell-border') solid;
    border-bottom: 0;
    border-top: 0;
  }
  text-align: center;
  padding: $mission-row-padding;
}

.borderRow {
  @include themify {
    border: 1px themed('cell-border') solid;
    border-top: 0;
  }
  height: 0;
}

.missionIcon {
  margin-bottom: 2px;
  margin-right: 5px;
  @include themify {
    fill: themed('mission_icon_color');
  }
}

.missionContainer {
  display: flex;
  flex-direction: row;
  align-content: baseline;
  align-items: center;
}

.subMission {
  padding-left: 32px;
}

.subMissionWrapper {
  display: flex;
  width: 100%;
}

.horizontalLine {
  @include themify {
    margin-left: 8px;
    width: 100%;
    border-bottom: 1px solid themed('table-info-icon');
    align-self: center;
  }
}
