@import 'styles/mixins.scss';
@import "src/styles/fonts-mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal {
  display: flex;
  align-items: center;
  max-width: 1100px !important;
}
.card {
  margin: 5px;
  height: 200px;
}

.inputHeader {
  @include font-input;
  padding-bottom: 8px;
}

.dayInput {
  margin-top: 20px;
}

.clearButton {
  float: right;
  @include themify {
    color: themed('color-red')
  }
}

.buttonContainer {
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addTaskButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newDateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include themify {
    color: themed('color-red')
  }
}

.iconAdd {
  margin: 5px;
}

.body {
  padding-bottom: 10px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.cardContent {
  min-width: 300px;
  max-width: 300px;
  min-height: 240px;
  max-height: 240px;
  display: flex; /* contexte sur le parent */
  justify-content: center; /* alignement vertical */
  vertical-align: center;
  align-items: center;
}
.informationTitle {
  @include font-subtitle;
  margin-top: 30px !important;
  padding-bottom: 5px;
}
.information {
  @include font-input;
  margin-top: 30px;
  margin-bottom: 10px;
}

.asterisk {
  @include themify {
    color: themed('color-red')
  }
}

.input {
  border-radius: 5px;
  padding: 3px 0;
  margin: auto;
  width: 100%;
  min-height: 40px;
  text-align: center;
  font-family: 'Outfit', 'Open Sans', sans-serif;
  @include font-text;

  &_enabled {
    @include themify {
      background-color: themed('table-background');
    }
  }
  
  &_disabled {
    cursor: default;
    pointer-events: none;
    @include themify {
      background-color: themed('input-background-disabled');
    }
  }
}

.container {
  width: 500px;
  border: 5px solid rgb(111, 41, 97);
  border-radius: 0.5em;
  padding: 10px;
}

.missionManagerContainer {
  display: flex;
  width: 100%;
}

.missionInput {
  flex-grow: 2;
  margin-right: 40px;
}

.managerInput {
  flex-grow: 0;
}

.itemCard {
  margin-top: 5px;
}

.button {
  @include themify {
    background-color: themed('table-background');
    color: themed('text-normal');
  }
}
.inputSmall {
  border-radius: 5px;
  margin: auto;
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: bold;

  // TODO: figure out why the text color is not working (override MUI style maybe?)
  @include themify {
    color: themed('text-normal');
  }

  &_enabled {
    @include themify {
      background-color: themed('table-background');
    }
  }
  
  &_disabled {
    cursor: default;
    pointer-events: none;
    color: gray !important;
    @include themify {
      background-color: themed('input-background-disabled');
    }

    input {
      color: gray !important;
    }
  }
}
