@use "sass:math";
@import 'src/styles/mixins.scss';
@import 'src/styles/fonts-mixins.scss';

$icon-width: 36px;
$icon-height: $icon-width;
$border: 5px;
$border-radius: $icon-width + $border;
$divider-width: 42px;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $icon-width + $border;
}

.activeStatus {
  width: $icon-width;
  height: $icon-height;
  border-radius: $border-radius;
  border: solid $border;
}

.inactiveStatus {
  width: $icon-width;
  height: $icon-height;
  border-radius: $border-radius;
  @include themify {
    background-color: themed('disabled-background');
    color: themed('mission_icon_color') !important;
  }
}

.divider {
  $divider-visible: math.div($divider-width, 3) * 2;
  $divider-separator: math.div($divider-width , 6);
  width: $divider-visible;
  margin-right: $divider-separator;
  margin-left: $divider-separator;
}

.activeText {
  position: relative;
  text-align: center;
  margin-top: 8px;
  left: -(math.div($divider-width , 2) + math.div($border , 2));
  width: $icon-width + $divider-width + math.div($border , 2);
  @include font-menu-apps;
}

.inactiveText {
  position: relative;
  text-align: center;
  margin-top: 8px;
  left: math.div($divider-width , -2);
  width: $icon-width + $divider-width;
  @include font-menu-apps;
  @include themify {
    color: themed('color-button-disabled');
  }
}
