@import 'styles/mixins.scss';
@import '../../../../styles/fonts-mixins';

.root {
  th,
  td {
    @include font-polytable-content;
    padding: 5px 5px;
  }

  th,
  td:first-child {
    padding-left: 15px;
  }

  th,
  td:last-child {
    padding-right: 15px;
  }
}
