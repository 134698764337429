@import 'src/styles/fonts-mixins';
@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  @include themify{
    color: themed('occupation');
  }
  @include bottom-card-text;
}

.occupation {
  margin-left: 5px;
}
