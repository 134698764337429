@import 'src/styles/mixins.scss';

.month-picker {
  position: fixed;
  z-index: 100;
}

.month-picker > .rmp-container {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 100;
  //top: -350px; // Make appear from the top
  visibility: hidden;
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container {
    position: fixed;
    //top: -350px; // Make appear from the top
    visibility: hidden;
    width: 100%;
    height: 100%;
    -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  }
}

.month-picker > .rmp-container.rmp-table {
  display: table;
}

.month-picker > .rmp-container.show {
  left: 0;
  //top: -350px; // Make appear from the top
  visibility: visible;
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.month-picker > .rmp-container.show .rmp-overlay {
  left: 0;
}

.month-picker > .rmp-container .rmp-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: -10000px;
  opacity: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: opacity;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
    opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
    opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
    opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-overlay {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.month-picker > .rmp-container .rmp-cell {
  display: table-cell;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-cell {
    vertical-align: bottom;
  }
}

.month-picker > .rmp-container .rmp-popup {
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  margin: 0 auto;
  z-index: 10;
  font-size: 1.2rem;
  opacity: 0;
  border-radius: 3px;
  padding: 0.4rem;
  box-sizing: content-box;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup {
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 14.4rem;
    -webkit-transform: translate3d(0, 14.4rem, 0);
    -moz-transform: translate3d(0, 14.4rem, 0);
    -ms-transform: translate3d(0, 14.4rem, 0);
    -o-transform: translate3d(0, 14.4rem, 0);
    transform: translate3d(0, 14.4rem, 0);
  }

  .month-picker > .rmp-container .rmp-popup.range {
    height: 28rem;
    -webkit-transform: translate3d(0, 28rem, 0);
    -moz-transform: translate3d(0, 28rem, 0);
    -ms-transform: translate3d(0, 28rem, 0);
    -o-transform: translate3d(0, 28rem, 0);
    transform: translate3d(0, 28rem, 0);
  }

  .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
    margin-top: 0.4rem;
  }

  .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup {
    -webkit-transform: translate3d(0, -64px, 0);
    -moz-transform: translate3d(0, -64px, 0);
    -ms-transform: translate3d(0, -64px, 0);
    -o-transform: translate3d(0, -64px, 0);
    transform: translate3d(0, -64px, 0);
    top: 0;
    width: 20rem;
  }
  .month-picker > .rmp-container .rmp-popup.range {
    width: 40.6rem;
    padding: 0.6rem;
  }
}

.month-picker > .rmp-container .rmp-popup.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.month-picker > .rmp-container .rmp-popup:after {
  content: ' ';
  clear: both;
  display: table;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad {
  position: relative;
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad {
    box-sizing: border-box;
    float: left;
    width: 20rem;
  }
  .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
    float: right;
  }
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
  display: block;
  font-size: 1.4rem;
  text-align: center;
  line-height: 3.4rem;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
  font-weight: normal;
  margin-right: 0.5em;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
  font-style: normal;
  text-align: center;
  width: 3.4rem;
  height: 3.4rem;
  line-height: 3.4rem;
  position: absolute;
  top: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
  left: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
  right: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad ul,
.month-picker > .rmp-container .rmp-popup .rmp-pad li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad ul {
  display: block;
  width: 100%;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
  content: ' ';
  clear: both;
  display: table;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad li {
  display: block;
  float: left;
  text-align: center;
  line-height: 3.4rem;
  font-size: 1.15rem;
  border-radius: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: background-color 200ms ease-in-out,
    color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    width: 25%;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    width: 33.3333333333%;
  }
}

.month-picker > .rmp-container .rmp-popup.light {
  color: #666;
  background-color: rgba(255, 255, 255, 0.96); // global background-color
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup.light {
    border-top: 1px solid #ccc;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08);
  }
  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
    border-top: 1px solid rgba(204, 204, 204, 0.5);
  }
  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
    border-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.light {
    border: 1px solid #ccc;
    box-shadow: 0 1px 5px #ddd;
  }
  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
    background-color: rgba(
      243,
      243,
      243,
      0.87
    ); // background container calendar
  }
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
    background-color: rgba(135, 135, 135, 0.44);
    color: white;
  }
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover {
  background-color: transparent;
  color: #bbb;
  cursor: default;
}
