@import 'src/styles/mixins.scss';
@import 'src/styles/fonts-mixins';

.tableContainer {
  overflow: unset;
}

.title {
  @include font-title-page;
  position: relative;
  top: 35px;
  height: 60px;
}

.missionNameCell {
  border: none;
  padding: 0 10px 14px 30px;
  @include ellipsis-text;
  display: block;
  max-width: 250px;
}

.unModifiableWeeksColor {
  @include themify {
    color: themed('unmodifiable-column-label');
  }
}

.handBackWeeksColor {
  @include themify {
    color: themed('hand-back-weeks');
  }
}

.toolTipText {
  @include tooltip;
}
