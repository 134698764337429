@import "src/styles/mixins.scss";

//////////////////////////
// Tables
// Polytable
// Others
//////////////////////////

@mixin font-menu-apps {
  font-size: 12px;
  font-weight: normal;
}

//////////////////////////
// Tables

@mixin font-month-name {
  font-size: 15px;
  font-weight: normal;
  @include themify {
    color: themed('text-normal') !important;
  }
  &:first-letter {
    text-transform: capitalize;
  }
}

@mixin font-activity-name {
  font-size: 14px !important;
  font-weight: bold !important;
  @include themify {
    color: themed('text-normal') !important;
  }
}

@mixin font-activity-subname {
  font-size: 14px !important;
  font-weight: bold !important;
  @include themify {
    color: themed('text-category') !important;
  }
}

//////////////////////////
// PolyTable
@mixin font-polytable-header {
  font-size: 14px !important;
  font-weight: normal !important;
  @include themify {
    color: themed('text-normal') !important;
    background-color: themed('table-row-background-even') !important;
  }
}

@mixin font-polytable-content {
  font-size: 14px !important;
  font-weight: normal !important;
  @include themify {
    color: themed('text-normal') !important;
  }
}

//////////////////////////
// Others


@mixin font-input-title {
  font-size: 12px !important;
  font-weight: normal !important;
  @include themify {
    color: themed('text-normal') !important;
  }
}

@mixin font-title-page {
  font-size: 24px;
  font-weight: normal;
  line-height: 20px;
  @include themify {
    color: themed('text-normal');
  }
}

@mixin font-title-card {
  font-size: 16px !important;
  font-weight: normal;
  @include themify {
    color: themed('text-normal') !important;
  }
}

@mixin font-subtitle {
  font-size: 18px !important;
  font-weight: bold !important;
  @include themify {
    color: themed('text-normal') !important;
  }
}

@mixin font-select {
  font-size: 13px;
  font-weight: normal;
  @include themify {
    color: themed('text-normal');
  }
}

@mixin font-input {
  font-size: 12px !important;
  font-weight: 500 !important;
  @include themify {
    color: themed('text-surface');
  }
  font-feature-settings: normal;
}

@mixin font-text {
  line-height: 16px;
  text-align: justify;
  font-size: 15px;
  font-weight: normal;
  @include themify {
    color: themed('text-normal');
  }
}

@mixin font-text-bold {
  @include font-text;
  font-weight: bold !important;
}

@mixin font-text-calendar {
  font-size: 8px;
  text-transform: uppercase;
}

@mixin font-icon {
  font-size: 20px !important;
  font-weight: bold;
}

@mixin font-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

@mixin font-info-display {
  font-size: 16px;
  font-weight: 400;
}

@mixin tooltip {
  text-align: center !important;
  max-width: 150px !important;
  font-size: 15px !important;
}


@mixin ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin bottom-card-text {
  font-size: 14px !important;
  font-weight: normal !important;
}
