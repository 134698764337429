@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';

.subTitleModal {
  @include themify {
    color: themed('color-red');
  }
  font-size: 15px;
  margin-bottom: 20px;
}
