@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.cover {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // max-width: $login-cover-max-width;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-size: cover;
  background-image: url('/../public/img/ban-polyconseil-v2.jpg');

  @include md {
    display: none;
  }

  &_logo {
    max-width: 350px;
  }

  &_accroche {
    color: white;
    margin-top: 40px;
    font-size: 2rem;
    @media (max-width: 1000px) {
      font-size: calc(1.2rem + 0.4vw);
    }
    font-family: 'Outfit', 'Open Sans' !important;
    font-weight: 100;
    & > span {
      font-weight: bold;
      font-family: 'Outfit', 'Open Sans' !important;
    }
  }
}
