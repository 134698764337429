@import 'styles/mixins.scss';

.backArrowContainer {
  display: table;
}

.backArrowContainerElement {
  display: table-cell;
  vertical-align: middle;
}

.backArrowIcon {
  @include themify {
    color: themed('')
  }
}

.backArrowText {
  padding: 0 10px;
}