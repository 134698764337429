@import 'styles/mixins.scss';
@import 'styles/variables.scss';
@import 'src/styles/fonts-mixins.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.icon {
  padding: 3px !important;
  margin-left: 5px !important;
  @include themify {
    color: themed('text-grey') !important;
  }
}

.avatar {
  margin: 12px;
  box-sizing: border-box;
  width: 30px !important;
  height: 30px !important;
}

.popoverPaper {
  overflow-x: unset !important;
  overflow-y: unset !important;
  background: unset !important;
  box-shadow: unset !important;
}

.notifActiveIcon {
  transform: scale(0.55, 0.55);
  color: #a12b25;
  position: absolute;
  bottom: 20%;
  left: 30%;
}
