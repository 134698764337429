@import 'styles/mixins.scss';
@import 'src/styles/fonts-mixins.scss';

.addressContainer {
    display: flex;
}

.addressColumn {
    @include themify {
        color: themed('text-primary');
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Outfit', 'Open Sans';
    font-size: 13px;
}

.addressTitle {
    margin-bottom: 18px;
    margin-right: 30px;
}

.addressText {
    margin-right: 7px;
    margin-top: 2px;
}
