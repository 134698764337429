@import 'src/styles/mixins.scss';
@import "src/styles/fonts-mixins";

.fullUnderline {
  @include themify {
    border-bottom: 1px solid themed('cell-border');
  }
}

.noUnderline {
  border-bottom: 0 solid;
  padding: 0;
}

.extraRightUnderline {
  @include themify {
    -webkit-box-shadow: inset 0px -1px themed('cell-border');
    -moz-box-shadow: inset 0px -1px themed('cell-border');
    box-shadow: inset 0px -1px themed('cell-border');
  }
  width: 20px;
  display: table-cell;
}

.underlinedCell {
  @include themify {
    -webkit-box-shadow: inset 0px -1px themed('cell-border');
    -moz-box-shadow: inset 0px -1px themed('cell-border');
    box-shadow: inset 0px -1px themed('cell-border');
  }
}

.missionNameCell {
  border: none;
  padding: 0 10px 14px 30px;
  @include ellipsis-text;
  text-align: center;
  display: block;
}

.monthSpan {
  @include font-month-name;
  align-items: center;
  text-align: center;
  padding: 0;

}

.arrowRightCell {
  @include themify {
    border: 1px solid themed('cell-border');
    border-left-width: 0;
    border-bottom-width: 0;
  }
  align-items: end;
  text-align: end;
  padding: 0 70px 0 0;

}

.arrowLeftCell {
  @include themify {
    border: 0 solid themed('cell-border');
    border-top-width: 1px;
  }
  align-items: start;
  text-align: start;
  padding: 0 0 0 30px;
}

.currentMonthDisplayer {
  @include themify {
    border-top: 1px solid themed('cell-border');
    border-right: 1px solid themed('cell-border');
  }
}

.changeMonthButton {
  padding: 5px;
}

.spanHeader {
  padding: 0;
  height: 10px;
}

.exportCell {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 0px;
  @include themify {
    border: 0 solid themed('cell-border');
    border-top-width: 1px;
    border-left-width: 1px;
  }
}

.exportCellContainer {
  display: flex;
  justify-content: flex-start;
}

.monthCell {
  @include themify {
    border: 0 solid themed('cell-border');
    border-top-width: 1px;
  }
}

.totalSpan {
  line-height: 1.2;
}

.invalidWeekChip {
  background-color: #f8f8f8;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  width: fit-content;
  display: flex;
  position: relative;
  left: 30px;
  margin-right: 5px;
}

.invalidWeekButton {
  border-radius: 20px;
  letter-spacing: normal;
  text-transform: none;
  color: #333333;
}

.invalidWeekButtonChecked {
  background-color: #A12B25;
  color: white;

  &:hover {
    background-color: #A12B25;
    color: white;
  }
}
