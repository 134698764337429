@keyframes createRowIn {
  from {
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes createRowOut {
  from {
    visibility: visible;
  }
  to {
    height: 0;
    visibility: collapse;
  }
}

@keyframes createElementIn {
  from {
    opacity: 0;
    height: 0;
    visibility: collapse;
    padding: 0;
    overflow: hidden;
    line-height: 0;
    font-size:0;
    margin:0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes createElementOut {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    height: 0;
    visibility: collapse;
    padding: 0;
    overflow: hidden;
    line-height: 0;
    font-size:0;
    margin:0;
  }
}

.rowIn {
  animation-name: createRowIn;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  td {
    animation-name: createElementIn;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    height: min-content;
    p {
      animation-name: createElementIn;
      animation-duration: 0.10s;
    }
    div {
      animation-name: createElementIn;
      animation-duration: 0.10s;
    }
    span {
      animation-name: createElementIn;
      animation-duration: 0.10s;
    }
    svg {
      animation-name: createElementIn;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
    }
    input {
      animation-name: createElementIn;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
    }
  }
}

.rowOut {
  animation-name: createRowOut;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  td {
    animation-name: createElementOut;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    height: min-content;
    div {
      animation-name: createElementOut;
      animation-fill-mode: forwards;
      animation-duration: 0.10s;
    }
    p {
      animation-name: createElementOut;
      animation-fill-mode: forwards;
      animation-duration: 0.10s;
    }
    span {
      animation-name: createElementOut;
      animation-fill-mode: forwards;
      animation-duration: 0.10s;
    }
    svg {
      animation-name: createElementOut;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
    }
    input {
      animation-name: createElementOut;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
    }
  }
}
