@import 'styles/mixins.scss';

.commentAuthorization {
  padding: 10px;
  resize: none;
  width: 98%;

  @include themify {
    color: themed('text-normal');
    border: 1px solid themed('input-border');
  }
  border-radius: 5px;

  font-size: 13px;
  font-family: 'Outfit', 'Open Sans', sans-serif;
}
