@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';

$valueCellWidth: 11.6%;
$maxCellWidth: 185px;
$revenueRowHeight: 60px;
$tableHeadHeight: 30px;
$poNameCellWidth: 17%;

.poWidth {
  width: $poNameCellWidth;
}

.noUnderline {
  border-bottom: 0 solid;
  padding: 0;
  height: $tableHeadHeight;
  th {
    height: max-content;
  }
}

.yearCell {
  text-align: center;
}

.rightArrowCell {
  text-align: right;
}

.headCell {
  position: relative;
  text-align: center;
  font-weight: bold;
}

.monthCell {
  text-transform: capitalize;
}

.bdcNameCell {
  text-align: justify;
  height: $revenueRowHeight;
  width: 20%;
  @include themify {
    border: 1px themed('cell-border') solid;
  }
}

.totalColumn {
  text-align: center;
  padding: 0px;
  white-space: nowrap;
  width: 10%;
  @include themify {
    border: 1px themed('cell-border') solid;
    border-right: 2px themed('cell-border') solid;
  }
}

.black {
  @include themify {
    color: themed('text-normal');
  }
}

.bold {
  @include font-text-bold;
}

.boldTotal {
  @include font-text-bold;
  text-align: center;
}

.regularTableCell {
  text-align: center;
  padding: 0px;
  white-space: nowrap;
  width: $valueCellWidth;
  max-width: $maxCellWidth;
  @include themify {
    border: 1px themed('cell-border') solid;
  }
  input {
    font-size: 14px;
  }
}

.cellsContent {
  position: relative;
  font-size: 14px;
  @include themify {
    color: themed('text-grey');
  }
}

.cellsIcon {
  position: absolute;
  padding: 0;
  top: 3px;
  right: 0;
  width: 30px;
  height: 30px;
}

.oldMonthCell {
  width: $valueCellWidth;
  max-width: $maxCellWidth;
  @include themify {
    background: themed('revenue-table-validated');
  }
}

.invalidCell {
  cursor: default;
  pointer-events: none;
  width: $valueCellWidth;
  max-width: $maxCellWidth;
  @include themify {
    border: 1px themed('cell-border') solid;
  }
}

.invalidCellBackground {
  @include themify {
    background: repeating-linear-gradient(
      135deg,
      themed('input-background-disabled'),
      themed('input-background-disabled') 14px,
      themed('hash-lines') 14px,
      themed('hash-lines') 16px
    );
  }
}

.invalidValidatedCellBackground {
  @include themify {
    background: repeating-linear-gradient(
      135deg,
      themed('disable-validated-background'),
      themed('disable-validated-background') 14px,
      themed('hash-lines') 14px,
      themed('hash-lines') 16px
    );
  }
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
}

.actionButton {
  width: 30px;
  height: 30px;
}

.saveButtonContainer {
  margin-top: 20px;
  text-align: right;
}

.inputRevenue {
  padding: 0px 10px;
}

.validationCell {
  border-bottom: 0 solid;
  height: 60px;
}

.changeMonthButton {
  @include themify {
    background-color: themed('text-grey');
    color: white;
  }
  border-radius: 0;
}

.check {
  position: absolute;
  margin-left: 56%;
}

.checked {
  @include themify {
    color: themed('approved-input');
  }
}

.info {
  @include themify {
    color: themed('hand-back-weeks');
  }
}

.fullUnderline {
  height: $tableHeadHeight;
  th {
    height: min-content;
  }
}

.linkIcon {
  @include themify {
    color: themed('text-grey');
  }
}

.link {
  display: flex;
  div {
    min-width: 0;
    @include ellipsis-text;
  }
  color: black;
  align-items: center;
}

.poName {
  @include ellipsis-text;
}

.link:hover {
  outline: black;
  text-decoration: underline !important;
}

.infoIcon {
  margin-right: 10px;
  @include themify {
    color: themed('text-grey');
  }
}

.emptyCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customAdornment {
  position: relative;
  bottom: 18px;
  left: 10px;
  width: 20px;
}

.italic {
  font-style: italic;
}

.poRow {
  height: $revenueRowHeight;
}
