@import 'src/styles/mixins';

.container {
  display: flex;
}

.labelInputFile {
  text-align: center;
  cursor: pointer;
}

.uploadButton {
  display: flex;
  width: 122px;
  height: 36px;
}

.button {
  width: 122px;
  height: 36px;
}

.icon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  align-self: center;
}

.filename {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 25px;
  padding-left: 10px;
  height: 100%;
  line-height: 180%;
}

.text {
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.sizeText {
  @include themify {
    color: themed('text-grey');
  }
}

.deleteIcon {
  color: red;
  font-size: 15px;
}
