@import 'src/styles/fonts-mixins';
@import 'src/styles/mixins';

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.checked {
  color: white;
}

.billTitle {
  margin-left: 5px;
}

.toBill {
  @include themify {
    color: themed('status-toBill');
  }
}

.toBillNew {
  @include themify {
    color: themed('status-toBillNew');
  }
}

.billed {
  @include themify {
    color : themed('status-billed');
  }
}

.sent {
  @include themify {
    color : themed('status-sent');
  }
}

.toCancel {
  @include themify {
    color : themed('status-billToCancel');
  }
}

.canceled {
  @include themify {
    color : themed('status-billCanceled');
  }
}

.recovering{
  @include themify {
    color : themed('status-billPaymentRecovering')
  }
}

.litigtion{
  @include themify {
    color : themed('status-billPaymentLitigation')
  }
}

.draft {
  @include themify {
    color : themed('status-billDraft');
  }
}

.billPaymentPending {
  @include themify {
    color : themed('status-billPaymentPending');
  }
}

.billPaymentLitigation {
  @include themify {
    color : themed('status-billLitigation');
  }
}

.billPaymentPaid {
  @include themify {
    color : themed('status-billPaymentPaid');
  }
}

.billPaymentRecovering {
  @include themify {
    color: themed('status-billPaymentRecovering')
  }
}
