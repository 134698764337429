@import 'styles/mixins.scss';
@import "src/styles/fonts-mixins.scss";

.dateRange {
  margin-top: auto;
}

.personIcon {
  @include themify {
    color: themed('icon-color');
  }
}

.autocompleteIcon {
  @include themify {
    color: themed('icon-color');
  }
  position: relative;
  top: 5px;
  right:10px;
}

.pageDescription {
  line-height: 14px;
  @include font-text;
}

.descriptionParagraph {
  margin: 0.7em;
}

.descriptionField {
  resize: none;
  width: 935px;
  min-height: 70px;
  padding: 10px 8px;

  @include themify {
    border: 1px solid themed('input-border');
  }
  border-radius: 5px;
  font-family: 'Outfit', 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
}
