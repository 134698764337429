@import 'src/styles/mixins.scss';
@import "src/styles/fonts-mixins";

.missionContainer {
  max-width: 400px;
  height: 30px;

  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verticallyCentered {
  vertical-align: middle;
}


.missionIcon {
  @include themify {
    color: themed('icon-color');
  }

  margin: 4px;
}

.missionText {
  @include ellipsis-text;
  @include themify {
    color: themed('text-primary');
  }
  font-size: 13px;
}
