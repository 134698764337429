@import 'styles/mixins.scss';
@import 'styles/fonts-mixins';

.input {
  &_disabled {
    border-radius: 8px;
    cursor: default;
    pointer-events: none;

    @include themify {
      background-color: themed('input-background-disabled');
      color: themed('text-grey');
    }
  }

  &_hatched {
    border-radius: 8px;
    cursor: default;
    pointer-events: none;

    @include themify {
      background: repeating-linear-gradient(135deg,
          themed('input-background-disabled'),
          themed('input-background-disabled') 14px,
          themed('input-border') 14px,
          themed('input-border') 16px);
    }
  }
}

.vacation {
  cursor: default;
  pointer-events: none;
  padding: 0;
  margin: 0;
  background: transparent;
  background-color: transparent !important;

  @include themify {
    color: themed('text-grey') !important;
  }
}