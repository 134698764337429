.filterSelect {
  margin-left: 10px;
}

.iconOption {
  margin-right: 10px;
}

.statusChip {
  margin: 2px;
}