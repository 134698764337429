@import 'styles/mixins.scss';

.cell {
  font-weight: bold;
  line-height: 16px;

  align-items: center;
  text-align: center;
  padding: 0;
  width: 154px;

  border-bottom: none;
  border-top: none;
  @include themify {
    color: themed('unmodifiable-cell');
  }
}

.oldBackground {
  @include themify {
    background: themed('table-background');
  }
}

.modifiableBackground {
  @include themify {
    background: themed('modifiable-background-cell');
  }
}

.currentBackground {
  @include themify {
    background: themed('table-background');
  }
}

.isInvalid {
  @include themify {
    background: themed('invalid-cell-background');
  }
}

.isCurrentWeek {
  @include themify {
    background: themed('current-week-background-cell');
  }
}
