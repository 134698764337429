$modalFixedHeight: 350px;

.fixedHeight {
  max-height: $modalFixedHeight !important;
  overflow: hidden; //tmp
  display: flex;
  flex-direction: column;
}

.contentMargin {
  margin: 0.5em;
}

.title {
  justify-content: left;
  padding-bottom: 10px !important;
}

.scroller {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10)
}

.footerContainer {
  width: 100%
}