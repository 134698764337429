@import 'src/styles/fonts-mixins';

.container {
  padding: 10px;
}

.paperContainer {
  margin-top: 30px;
}

.midTitle {
  margin-bottom: 30px;
  margin-top: 40px;
}

.addButton {
  margin-top: 30px;
  margin-bottom: 20px;
}

.row {
  height: 50px;
}

.iconPosition {
  float: right;
}

.collaboratorLabel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.collaboratorLabelButton {
  top: -5px;
}

.collaboratorADRLabel {
  margin-top: 7px;
}

.fileContainer {
  display: flex;
}

.fileIcon {
  margin-right: 4px;
}

.attachIcon {
  margin-right: 7px;
}

.editIcon {
  margin-right: 7px;
}

.doughnut {
  margin-right: 40px;
}

.detailSecondContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 8px;
}

.subMissionAdr {
  margin-left: 10px;
  margin-top: 7px;
}
