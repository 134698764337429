@import 'styles/mixins.scss';

.root {
  border-collapse: separate !important;
  overflow-x: hidden;

  tr:nth-child(even) {
    @include themify {
      background-color: themed('table-row-background-even');
    }
  }
}
