@import 'styles/mixins.scss';
@import 'styles/fonts-mixins.scss';
@import 'styles/variables.scss';

.container {
  display: flex;
  align-items: center;
  @include themify {
    color: themed('text-surface');
  }
}

.disabled {
  @include themify {
    background-color: themed('disabled-background');
    color: themed('text-label');
  }
}


.onFocus {
  :focus {
    @include themify {
      background-color: themed('color-background');
    }
  }
}
