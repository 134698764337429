@import 'styles/mixins.scss';

.fullUnderline {
  @include themify {
    border-bottom: 1px solid themed('cell-border');
  }
}

.extraRightUnderline {
  @include themify {
    -webkit-box-shadow: inset 0px -1px themed('cell-border');
    -moz-box-shadow: inset 0px -1px themed('cell-border');
    box-shadow: inset 0px -1px themed('cell-border');
  }
  width: 20px;
  display: table-cell;
}

.firstCellBorder {
  @include themify {
    border-bottom: 1px solid themed('cell-border');
  }
  width: 92%;
  float: right;
}

.underlinedCell {
  @include themify {
    -webkit-box-shadow: inset 0px -1px themed('cell-border');
    -moz-box-shadow: inset 0px -1px themed('cell-border');
    box-shadow: inset 0px -1px themed('cell-border');
  }
}

.underlinedInvalidCell {
  @include themify {
    background: themed('invalid-cell-background');
  }
}

.firstInvalidCell {
  @include themify {
    background: themed('invalid-cell-background');
  }
}

.currentWeekCell {
  @include themify {
    background: themed('current-week-background-cell');
  }
}

.noBorder {
  border: none;
  border-bottom: none;
}
