@import 'styles/mixins.scss';
@import '../../../../styles/fonts-mixins';

$border: #c6c6c6;
$radius: 4px;

.root {
  th {
    background-color: #fafafa;
    padding: 10px 5px;
    line-height: 0.9rem;
    @include font-polytable-header;

    @include themify {
      border-bottom: 1px solid $border !important;
      border-top: 1px solid $border !important;
    }
  }

  th:first-child {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    padding-left: 15px;
    @include themify {
      border-left: 1px solid $border !important;
    }
  }

  th:last-child {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
    padding-right: 15px;
    @include themify {
      border-right: 1px solid $border !important;
    }
  }
}
