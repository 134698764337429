@import 'styles/mixins.scss';

.row {
  border: none !important;
}

.cell {
  border: none !important;
}

.bottomInvalidCell {
  @include themify {
    color: themed('bad-input');
  }
}
