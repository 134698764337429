@import 'src/styles/fonts-mixins';
@import 'src/styles/mixins.scss';
@import 'src/styles/animation';


/*CSS for grid container */
.gridContainer {
  @include fadeAnimationLonger;
  display: flex;
  flex-direction: column;
  padding: 18px 20px 18px 20px;
  strong { font-weight: bold; }
  em {font-style: italic;}
  p { margin-bottom: 15px }
}
