@import "src/styles/mixins";
@import 'src/styles/fonts-mixins.scss';

.maxWidth {
  @include ellipsis-text;
}


.verticalBaselineAlign {
  vertical-align: baseline;
}

.noResult {
  @include font-label;
  text-align: center;
  font-size: 20px;
  margin: 15px;
}

.checkHead {
  width: 20px;
}

.checkBox {
  padding: 0;
}
