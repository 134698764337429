@import 'src/styles/animation';
@import 'src/styles/fonts-mixins';

.margin_top_title {
  margin-top: 20px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.filters {
  display: flex;
}

.statusFilterContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loader {
  text-align: center;
  padding: 40px;
  @include fadeAnimationLonger;
}

.billTable {
  @include fadeAnimationLonger;
}

.description {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.text {
  display: flex;
  justify-content: space-between;
  @include font-text;
}

.status {
  transform: scale(0.9);
}
