@import 'src/styles/mixins';
@import 'src/styles/fonts-mixins';

$valueCellWidth: 11.6%;
$maxCellWidth: 185px;
$revenueRowHeight: 60px;
$tableHeadHeight: 30px;
$poNameCellWidth: 20%;

.nameCellWidth {
  width: $poNameCellWidth;
}

.noUnderline {
  border-bottom: 0 solid;
  padding: 0;
  height: $tableHeadHeight;
  th {
    height: max-content;
  }
}

.yearCell {
  text-align: center;
}

.headCell {
  position: relative;
}

.monthCell {
  text-transform: capitalize;
  align-items: center;
}

.clientNameCell {
  text-align: justify;
  @include themify {
    border: 1px themed('cell-border') solid;
  }
}

.missionText {
  @include themify {
    color: themed('mission_icon_color');
  }
}

.totalColumn {
  text-align: center;
  padding: 0;
  white-space: nowrap;
  @include themify {
    border: 1px themed('cell-border') solid;
    border-right: 2px themed('cell-border') solid;
  }
}

.bold {
  @include font-text-bold;
}

.boldTotal {
  @include font-text-bold;
  text-align: center;
}

.regularTableCell {
  text-align: center;
  padding: 0;
  white-space: nowrap;
  @include themify {
    border: 1px themed('cell-border') solid;
    background: themed('revenue-table-validated');
  }
}

.invalidCell {
  cursor: default;
  @include themify {
    border: 1px themed('cell-border') solid;
    background: repeating-linear-gradient(
      135deg,
      themed('input-background-disabled'),
      themed('input-background-disabled') 14px,
      themed('hash-lines') 14px,
      themed('hash-lines') 16px
    );
  }
}

.changeMonthButton {
  @include themify {
    background-color: themed('text-grey');
    color: white;
  }
  border-radius: 0;
}

.monthContainer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
}

.fullUnderline {
  height: $tableHeadHeight;
  th {
    height: min-content;
  }
}

.nameText {
  min-width: 0;
  @include ellipsis-text;
}

.link {
  display: flex;
  align-items: center;
  text-align: left;
  @include themify {
    color: themed('mission_icon_color');
  }
}

.link:hover {
  @include themify {
    color: themed('mission_icon_color');
  }
  text-decoration: underline !important;
}

.infoIcon {
  margin-right: 10px;
  @include themify {
    color: themed('text-grey');
  }
}

.revenueRowHeight {
  height: $revenueRowHeight;
}

.emptyContent {
  height: 100%;
  width: 100%;
}
