@import 'src/styles/mixins.scss';

.root {
  display: flex;
  align-items: center;
}

.icon {
  height: 15px;
  margin-right: 5px;
  width: 15px;
}

.text {
  font-size: 11px;
}
