@import "src/styles/fonts-mixins";

.label {
  @include font-label;
  margin-bottom: 5px;
  height: 16px;
}

.content {
  @include font-info-display;
}

.container {
  margin: 10px;
}